import React, { useEffect, useMemo, useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import * as RD from '@devexperts/remote-data-ts';
import { pipe } from 'fp-ts/function';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import 'react-phone-input-2/lib/style.css';
import 'swiper/swiper-bundle.min.css';
import 'animate.css';

import './css/main.css';
import './assets/variables.css';
import './assets/custom.css';
import './assets/App.css';
import './assets/index.css';

import { usePortfoliosUpdateEffects } from '~/shared/hooks';
import { AppContext, SettingsContext, ThemeContext } from '~/contexts/app';
import Loader from '~/ui/components/Loader/Loader';
import Routes from '~/routes';
import * as analytics from '@utils/analytics';
import { appReducer, appInitialState } from '@store/application/reducers';
import { loadProfileSettingAction, loadUserSettingAction } from '@store/settings';
import { MaintenancePage } from '@pages/Maintenance';
import { fetchOrganization } from '@store/organization/actions';
import { selectOrganization } from '@store/organization/selectors';
import { ERROR_PAGE } from '@routes/names';
import { useAuthentication } from '~/domain/effects/auth';
import { useTecton } from '~/packages/tecton';
import { registerLinksHandler } from '~/services/linksHandler';
import { useUpdateActivePortfolio, useIsAppDisabled, useLoadActivePortfolio } from '~/shared/hooks';
import { useActivePortfolioInfo, usePortfolioChangesEffects } from '~/entities/protfolio';
import { loadTopicsAction } from '~/entities/topic';
import { NotificationGlobalStyles } from '~/contexts/notifications';
import { IdleTime } from '~/widgets/IdleTime';
import { useSurveyNotification } from '~/features/Survey';
import { useEmailNotifications } from '~/features/EmailNotifications';

import './ui/antd.overrides.css';

const App = () => {
  const appLoading = useAuthentication();

  const [{ title, visual, behaviour }, appDispatch] = useReducer(appReducer, appInitialState);
  const updateActivePortfolio = useUpdateActivePortfolio();
  const [_, updatePortfoliosInfo] = useActivePortfolioInfo();
  const isAppDisabled = useIsAppDisabled();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  useTecton();

  const organization = useSelector(selectOrganization);

  useLoadActivePortfolio();

  useEmailNotifications();

  useEffect(() => {
    const unsubscribe = registerLinksHandler();
    return () => {
      unsubscribe();
    };
  }, []);

  const isDisabled = useMemo(
    () =>
      pipe(
        isAppDisabled,
        RD.getOrElse(() => true),
      ),
    [isAppDisabled],
  );

  useEffect(() => {
    analytics.init();
  }, []);

  useEffect(() => {
    const timeout = setTimeout(() => {
      const container = document.querySelector('#app');
      if (container) {
        container.scrollTo({ top: 0, behavior: 'smooth' });
        window.ekoScrollOutsideToTop();
      }
    }, 100);

    return () => {
      clearTimeout(timeout);
    };
  }, [location.href]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  useEffect(() => {
    dispatch(loadProfileSettingAction());
    dispatch(loadUserSettingAction());
    dispatch(loadTopicsAction());
    dispatch(fetchOrganization());
  }, []);

  useEffect(() => {
    updatePortfoliosInfo();
  }, [updatePortfoliosInfo]);

  useEffect(() => {
    updateActivePortfolio();
  }, [updateActivePortfolio]);

  usePortfoliosUpdateEffects();
  usePortfolioChangesEffects();

  const isSurveyEnabled = useMemo(
    () =>
      pipe(
        isAppDisabled,
        RD.map((val) => !val && !appLoading && pathname !== ERROR_PAGE),
        RD.getOrElse(() => false),
      ),
    [appLoading, isAppDisabled, pathname],
  );

  useSurveyNotification(isSurveyEnabled);

  const themeValue = useMemo(
    () => ({
      logoURL: visual.logo,
      displayName: visual.displayName,
      showEmoji: visual.isEmojiOn,
    }),
    [visual.logo, visual.displayName, visual.isEmojiOn],
  );

  const settingsValue = useMemo(
    () => ({
      achSelectionType: behaviour.achSelectionType,
      passwordChangeAvailable: behaviour.passwordChangeAvailable,
    }),
    [behaviour.achSelectionType, behaviour.passwordChangeAvailable],
  );

  const appValue = useMemo(() => (action) => appDispatch(action), [appDispatch]);

  const routesContent = useMemo(
    () => (
      <>
        {!!organization?.meta?.idle_config?.idle_time && !!organization?.meta?.idle_config?.prompt_before_idle_time && (
          <IdleTime
            idleTime={organization.meta.idle_config.idle_time}
            promptBeforeIdleTime={organization.meta.idle_config.prompt_before_idle_time}
            title={organization?.meta?.idle_config?.prompt_title}
            text={organization?.meta?.idle_config?.prompt_text}
          />
        )}
        <Routes />
      </>
    ),
    [organization?.meta?.idle_config],
  );

  return (
    <main>
      {RD.isPending(isAppDisabled) || appLoading ? (
        <div className="vh-100 d-flex justify-content-center align-items-center">
          <Loader />
        </div>
      ) : (
        <>
          {visual.styles && <style>{visual.styles}</style>}
          <NotificationGlobalStyles />
          <AppContext.Provider value={appValue}>
            <SettingsContext.Provider value={settingsValue}>
              <ThemeContext.Provider value={themeValue}>
                {isDisabled ? <MaintenancePage /> : routesContent}
              </ThemeContext.Provider>
            </SettingsContext.Provider>
          </AppContext.Provider>
        </>
      )}
    </main>
  );
};

export default App;
